export const jsonPostRequest = async (route, requestBody) => {
  let body = {};

  const res = await fetch(route, {
    body: JSON.stringify(requestBody),
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    credentials: 'include',
  });

  if (res.headers.get('content-type').includes('application/json')) {
    body = await res.json();
  }

  return res.ok ? body : Promise.reject(body.message);
};

export const checkIfScrolledToBottom = (e, threshold = 1) => {
  const { target } = e;
  const offset = target.scrollTop + target.clientHeight + threshold;
  return offset >= target.scrollHeight;
};
